$components-custom-gradient-picker__padding: 3px; // 24px container, 18px handles inside, that leaves 6px padding, half of which is 3.
$grid-unit-10: 10px;
$button-size-small: 24px;

.components-custom-gradient-picker {
	margin-top: $grid-unit-10;
}

.components-custom-gradient-picker__gradient-bar:not(.has-gradient) {
	opacity: 0.4;
}

.components-custom-gradient-picker__gradient-bar {
	width: 100%;
	height: $button-size-small;
	border-radius: $button-size-small;
	margin-bottom: $grid-unit-10;
	padding-left: $components-custom-gradient-picker__padding;
	padding-right: $button-size-small - $components-custom-gradient-picker__padding;

	.components-custom-gradient-picker__markers-container {
		position: relative;
	}

	.components-custom-gradient-picker__insert-point {
		border-radius: 50%;
		background: $white;
		padding: 2px;
		min-width: $button-size-small;
		width: $button-size-small;
		height: $button-size-small;
		position: relative;

		svg {
			height: 100%;
			width: 100%;
		}
	}

	.components-custom-gradient-picker__control-point-button {
		border: 2px solid $white;
		border-radius: 50%;
		height: 18px;
		padding: 0;
		position: absolute;
		width: 18px;
		top: $components-custom-gradient-picker__padding;

		&.is-active {
			background: #fafafa;
			color: #23282d;
			border-color: #999;
			box-shadow:
				0 0 0 1px $white,
				0 0 0 3px $blue-medium-focus;
		}
	}
}

.components-custom-gradient-picker__color-picker-popover .components-custom-gradient-picker__remove-control-point {
	margin-left: auto;
	margin-right: auto;
	display: block;
	margin-bottom: 8px;
}

.components-custom-gradient-picker__inserter {
	width: 100%;
}

.components-custom-gradient-picker__liner-gradient-indicator {
	display: inline-block;
	flex: 0 auto;
	width: 20px;
	height: 20px;
}

.components-custom-gradient-picker__ui-line {
	display: flex;
	justify-content: space-between;
}

.components-custom-gradient-picker .components-custom-gradient-picker__ui-line {

	.components-base-control.components-angle-picker,
	.components-base-control.components-custom-gradient-picker__type-picker {
		margin-bottom: 0;
	}
}

.components-custom-gradient-picker .components-custom-gradient-picker__toolbar {
	border: none;

	// Work-around to target the inner button containers rendered by <ToolbarGroup />
	> div + div {
		margin-left: 1px;
	}

	button {

		&.is-pressed {

			> svg {
				background: $white;
				border: 1px solid $dark-gray-200;
				border-radius: 2px;
			}
		}
	}
}
